import cn from 'classnames';
import type { AnchorHTMLAttributes, MouseEventHandler } from 'react';
import { useLocation } from 'react-use';
import { navigate /*prefetch*/ } from 'vite-plugin-ssr/client/router';
import { resolveRoute } from 'vite-plugin-ssr/routing';

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  active?: boolean;
  activeClassName?: string;
  activeMatch?: string;
}
export const Link = ({
  active,
  activeClassName,
  activeMatch,
  ...props
}: LinkProps) => {
  const location = useLocation();

  let isActive = active;
  if (isActive === undefined && activeClassName) {
    if (activeMatch) {
      isActive = resolveRoute(activeMatch, location.pathname ?? '/').match;
    } else {
      isActive = resolveRoute(
        props.href ?? '/',
        location.pathname ?? '/'
      ).match;
    }
  }

  const handleMouseEnter: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (props.target === '_blank') return;
    e.preventDefault();
    /*props.href && prefetch(props.href);*/
  };

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (props.target === '_blank') return;
    e.preventDefault();
    props.onClick?.(e);
    props.href && navigate(props.href);
  };

  return (
    <a
      {...props}
      className={cn(props.className, {
        [activeClassName ?? '']: isActive,
      })}
      onMouseEnter={handleMouseEnter}
      onClick={handleClick}
    />
  );
};
